<template>
  <transition name="fade" appear appear-active-class="fade-enter-active">
    <div>


      <div class="grid30" v-for="(stage, stageIndex) in contract.stages" :key="stageIndex+'_stage'">
        <div class="etap">
          <div>
            <h2>Этап {{ stageIndex + 1 }}</h2>
            <p>{{ stage.name }}</p>
          </div>
          <div>
            <h4>сумма</h4>
            <p class="summ">{{ stage.stageSum | formatPrice }}</p>

            <h4>дата окончания</h4>
            <p>{{ stage.endDate | formatDate }}</p>

            <div class="badge" v-if="stage.paid">Этап оплачен</div>
          </div>
        </div>
        <div>
          <h2>Документы</h2>
          <p><router-link :to="contract.stages.length > 1 ? 'files/Отчетные документы/Этап '+(stageIndex+1) : 'files/Отчетные документы'">отчетные документы</router-link></p>
          <p><router-link :to="contract.stages.length > 1 ? 'files/Сопроводительные документы/Этап '+(stageIndex+1) : 'files/Сопроводительные документы'">сопроводительные документы</router-link></p>
          <p><router-link :to="contract.stages.length > 1 ? 'files/Акты/Этап '+(stageIndex+1) : 'files/Акты'">акты выполненных работ</router-link></p>
          <p><router-link :to="contract.stages.length > 1 ? 'files/Счета/Этап '+(stageIndex+1) : 'files/Счета'">счета</router-link></p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: "StagesTab",
  props: ['contract'],
  data(){
    return {
      actDirEmpty: [],
    }
  },
  methods: {
  },
  async beforeMount() {
    // for(let i = 0; i < this.contract.stages.length; i++){
    //   let req = await api.get('/storage/getFilesList?containerId=' + this.contract.fileStorageContainerId + '&path=/Акты/Этап ' + i, 'acts')
    //   if(req.ok) {
    //     this.actDirEmpty =
    //   }
    // }
  }
}
</script>

<style scoped>

</style>